/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'

import WidgetInstallsRatings from './widget-installs-ratings'
import Button from './button'

import StartpageHomepage from '../../../images/4624/startpage-homepage.svg'

import CheckmarkIcon from '../../../images/checkmark-icon.svg'
import {isEdge} from '../../../util/userAgent'

const Section1 = ({experiment, languageCode, showStoreInfo, bulletList, tagline, ctaText}) => {
    const translations = {
        en: {
            headline: `The world's most private search engine`,
            alt: `Prioritize your privacy and begin every search with Startpage, the world’s most private search engine.`,
        },
        de: {
            headline: `Die sicherste Suchmaschine der Welt`,
            alt: `Schütze deine Privatsphäre und suche mit Startpage, der privatesten Suchmaschine der Welt.`,
        },
    }
    const text = translations[languageCode]
    return (
        <section
            css={css`
                background: linear-gradient(90deg, #ddfffd 1.88%, #e2e5fe 94.65%);
                padding: 0 1rem 6rem;
            `}
        >
            <div
                className="container"
                css={css`
                    max-width: 1150px;
                    margin: 0 auto;
                    justify-content: space-between;
                    display: flex;
                `}
            >
                <div className="left-side">
                    <div>
                        <h1
                            css={css`
                                font-size: 40px;
                                margin: 3rem 0 2rem;
                                max-width: 488px;
                                font-weight: 800;
                                letter-spacing: 0.33px;
                            `}
                        >
                            {text.headline}
                        </h1>
                        <div
                            className="checkmark-list"
                            css={css`
                                p {
                                    display: flex;
                                    align-items: center;
                                    margin: 0 0 16px;
                                    font-size: 16px;
                                }
                                svg {
                                    margin-right: 12px;
                                    transform: scale(0.8);
                                }
                            `}
                        >
                            {bulletList.map((item, i) => {
                                const {bulletText} = item
                                return (
                                    <p key={i}>
                                        <CheckmarkIcon /> {bulletText}
                                    </p>
                                )
                            })}
                        </div>
                        <p
                            css={css`
                                margin: 32px 0;
                                max-width: 370px;
                                font-size: 16px;
                            `}
                        >
                            {tagline}
                        </p>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                margin-top: 2rem;
                                button {
                                    margin-right: 1rem;
                                }
                            `}
                        >
                            <Button
                                ctaType="cta-2"
                                experiment={experiment}
                                text={ctaText}
                                languageCode={languageCode}
                            />
                            {showStoreInfo && (
                                <div
                                    css={css`
                                        transform: scale(0.95);
                                    `}
                                >
                                    {!isEdge() && <WidgetInstallsRatings languageCode={languageCode} />}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className="right-side"
                    css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    `}
                >
                    <StartpageHomepage
                        css={css`
                            transform: scale(1.2);
                            margin: 110px 0 0;
                        `}
                        alt={text.alt}
                    />
                </div>
            </div>
        </section>
    )
}

Section1.propTypes = {
    experiment: PropTypes.string,
    languageCode: PropTypes.string,
    showStoreInfo: PropTypes.bool,
    headline: PropTypes.string,
    bulletList: PropTypes.array,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
}

Section1.defaultProps = {
    experiment: `sp-415`,
    languageCode: `en`,
    showStoreInfo: true,
}

export default Section1
