import {css} from '@emotion/react'
import StartpageLogoNew from '../../../images/4624/startpage-logo.svg'
import Button from './button'
import {colors} from '../../../constants'
import {useOnce} from '../../../util/customHooks'

const Header = ({ctaText, experiment, languageCode}) => {
    const translations = {
        en: {
            alt: 'Startpage home page',
        },
        de: {
            alt: 'Startseite von Startpage',
        },
    }

    const text = translations[languageCode]

    useOnce(() => {
        const header = document.querySelector('#header-landing')
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                header.style.boxShadow = colors.boxShadow
            } else {
                header.style.boxShadow = 'none'
            }
        })
    })

    return (
        <div
            id="header-landing"
            className="header"
            css={css`
                padding: 20px 1rem;
                width: 100%;
                background: ${colors.whisperLight};
            `}
        >
            <div
                className="container"
                css={css`
                    max-width: 1150px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <a
                    href={`https://www.startpage.com${window.location.href.match('/de') ? '/de' : ''}`}
                    rel="noopener noreferrer"
                    alt={text.alt}
                    aria-label="Startpage home page"
                >
                    <StartpageLogoNew
                        alt="home"
                        css={css`
                            width: 192px;
                        `}
                    />
                </a>
                <Button ctaType="cta-1" experiment={experiment} text={ctaText} languageCode={languageCode} />
            </div>
        </div>
    )
}

export default Header
