import React from 'react'
import {css} from '@emotion/react'
import Hero from './Hero'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
// import Section4 from './Section4'
import Footer from './Footer'
import {EVENTS, logDplWithAppParams} from '../../../util/dpl'
import {getCountry} from '../../../util/getCountry'
import {logSgc} from '../../../util/extensionMetadata'
import {useOnce} from '../../../util/customHooks'
import SEO from '../../seo'
import {isUnsupportedAppOS} from '../../../util/userAgent'
import UnSupported from './UnSupported'
import Waitlist from './Waitlist'
import {colors} from '../../../constants'
import {logHoudini} from '../../../util/houdini'

const AppV1 = ({os, browser, experimentId, languageCode = `en`}) => {
    useOnce(() => {
        logDplWithAppParams(EVENTS.StartpageAppPageLoad, {
            os,
            country: getCountry(),
            experiment: experimentId,
        })
        logHoudini(experimentId)
        logSgc()
    })

    const Head = () => (
        <SEO
            title="Install Startpage App"
            description="Search without tracking or profiling on Startpage."
            ogImagePath="https://www.startpage.com/sp/cdn/images/startpage-app-favicon.png"
            favicons={[
                {
                    rel: `icon`,
                    type: `image/png`,
                    sizes: `120x120`,
                    href: `https://www.startpage.com/sp/cdn/images/startpage-app-favicon.png`,
                },
            ]}
        />
    )

    if (isUnsupportedAppOS()) {
        return (
            <>
                <Head />
                <UnSupported os={os} browser={browser} experimentId={experimentId} languageCode={languageCode} />
            </>
        )
    }

    return (
        <>
            <Head />
            <div
                className="app-v1"
                css={css`
                p, span {
                    color ${colors.black} !important;
                }
            `}
            >
                <Hero experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
                <Section1 experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
                <Section2 experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
                <Section3 experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
                <Waitlist os={os} languageCode={languageCode} />
                {/* leaving the code below intentionally. we will wanna switch to this eventually until android is done */}
                {/* {isAndroid() ? (
                    <Waitlist languageCode={languageCode} />
                ) : (
                    <Section4 experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
                )} */}
                <Footer experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
            </div>
        </>
    )
}

export default AppV1
