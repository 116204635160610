/* eslint-disable camelcase */
import Layout from '../../layout'
import {globalStylesProtection} from '../../../constants'
import {useExperiment} from '../../../experimentFramework/experiment'
import EXPERIMENT_CONFIG from '../../../experimentFramework/experimentConfigApp.json'

import AppV1 from './AppV1'
import {getUserAgent, isIOS, isAndroid} from '../../../util/userAgent'

const AppPage = ({languageCode = `en`}) => {
    let os = 'unsupported'
    if (isIOS()) os = 'ios'
    if (isAndroid()) os = 'android'
    const {experiment: experimentId = `app-v1`} = useExperiment(EXPERIMENT_CONFIG, os)
    return (
        <Layout globalStyles={globalStylesProtection}>
            <AppV1 os={os} browser={getUserAgent()} experimentId={experimentId} languageCode={languageCode} />
        </Layout>
    )
}

export default AppPage
