import {css} from '@emotion/react'
import {useState} from 'react'
import {useOnce} from '../../../util/customHooks'
import {colors} from '../../../constants'
import {createMarkup} from '../../../util/datoCmsDataFactory'

const FirefoxMask = ({languageCode}) => {
    const translations = {
        en: {
            headline: 'To install Startpage:',
            steps: ['Click <b>Continue to installation</b>', 'Then click <b>Add</b>'],
        },
        de: {
            headline: 'So installierst du Startpage:',
            steps: [
                'Klicke auf <b>Hinzufügen</b>',
                '<b>Erlaube die Ausführung in privaten Fenstern</b> und klicke dann auf <b>OK</b>.',
            ],
        },
    }

    const text = translations[languageCode]

    const [show, setShow] = useState(false)
    useOnce(() => {
        window.addEventListener('showFirefoxMask', () => {
            setShow(true)
        })
    })
    return (
        <div
            className="firefox-mask"
            css={css`
                width: 100%;
                height: 100%;
                z-index: 99999;
                position: fixed;
                top: 0;
                left: 0;
                background: ${colors.black};
                opacity: 0.9;
                display: ${show ? 'flex' : 'none'};
                justify-content: center;
                flex-direction: column;
            `}
        >
            <div
                className="firefox-mask__content"
                css={css`
                    h2,
                    li,
                    b {
                        color: ${colors.whisperLight};
                    }
                    h2 {
                        font-size: 40px;
                        font-weight: 400;
                        margin-bottom: 1rem;
                    }
                    ol {
                        padding: 0 0 0 45px;
                        font-size: 40px;
                        line-height: 58px;
                    }
                    display: flex;
                    justify-content: center;
                    max-width: 800px;
                    margin: auto;
                `}
            >
                <div className="inner">
                    <h2>{text.headline}</h2>
                    <ol>
                        {text.steps.map((step) => {
                            return <li key={step} dangerouslySetInnerHTML={createMarkup(step)} />
                        })}
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default FirefoxMask
