/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'

import Img from 'gatsby-image'
import {graphql, useStaticQuery} from 'gatsby'
import WidgetInstallsRatings from './widget-installs-ratings'
import Button from './button'

import {isEdge} from '../../../util/userAgent'
import {colors} from '../../../constants'

const Section5 = ({experiment, showStoreInfo, languageCode, headline, description, tagline, ctaText}) => {
    const site = useStaticQuery(graphql`
        query Section54624Query {
            globeShield: file(relativePath: {eq: "4624/globe-shield.png"}) {
                childImageSharp {
                    fixed(height: 255, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)

    const translations = {
        en: {
            imgAlt:
                "A shield featuring Startpage's logo safeguards Earth, emphasizing their mission to protect privacy worldwide",
        },
        de: {
            imgAlt:
                'Ein Schild mit dem Logo von Startpage schützt die Erde und steht für die Mission für mehr Privatsphäre',
        },
    }

    const text = translations[languageCode]

    return (
        <section
            css={css`
                padding: 6rem 1rem;
                @media (max-width: 900px) {
                    padding: 2rem 1rem 6rem;
                }
            `}
        >
            <div
                className="container"
                css={css`
                    max-width: 1150px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: space-between;
                    @media (max-width: 900px) {
                        display: block;
                        transform: scale(0.85);
                    }
                `}
            >
                <div
                    className="left-side"
                    css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-right: 150px;

                        @media (max-width: 900px) {
                            width: 100%;
                            align-items: center;
                        }
                    `}
                >
                    <Img alt={text.imgAlt} fixed={site.globeShield.childImageSharp.fixed} />
                </div>

                <div
                    className="right-side"
                    css={css`
                        width: 100%;
                    `}
                >
                    <div
                        css={css`
                            margin: 0 auto;
                        `}
                    >
                        <h2
                            css={css`
                                font-size: 40px;
                                font-weight: 800;
                                margin: 2rem 0;
                            `}
                        >
                            <span
                                className="highlight"
                                css={css`
                                    background: linear-gradient(
                                        180deg,
                                        rgba(255, 255, 255, 0) 60%,
                                        ${colors.purpleLight} 50%
                                    );
                                `}
                            >
                                {headline}
                            </span>
                        </h2>
                        <p
                            css={css`
                                margin: 1.5rem 0 30px;
                                font-size: 16px;
                                max-width: 500px;
                            `}
                        >
                            {description}
                        </p>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                margin-top: 1rem;
                            `}
                        >
                            <Button
                                text={ctaText}
                                ctaType="cta-6"
                                experiment={experiment}
                                languageCode={languageCode}
                            />
                            {showStoreInfo && (
                                <div
                                    css={css`
                                        transform: scale(0.95);
                                        margin-left: 1rem;
                                    `}
                                >
                                    {!isEdge() && <WidgetInstallsRatings languageCode={languageCode} />}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

Section5.propTypes = {
    experiment: PropTypes.string,
    showStoreInfo: PropTypes.bool,
    languageCode: PropTypes.string,
    headline: PropTypes.string,
    description: PropTypes.string,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
}

Section5.defaultProps = {
    experiment: `sp-415`,
    showStoreInfo: true,
}

export default Section5
