import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Global, css} from '@emotion/react'
import SEO from '../../seo'
import {logDplWithExtensionParams, EVENTS} from '../../../util/dpl'
import {setExtensionParamsToLocalStorage} from '../../../util/extensionMetadata'

import Section1 from './section1'
import Section2 from './section2'
import Section3 from './section3'
import Section4 from './section4'
import Section5 from './section5'
import Footer from './footer'
import Header from './header'
import {colors, legacyExtensionFavicons} from '../../../constants'
import FirefoxMask from './firefox-mask'

const Index4624 = ({
    experiment,
    languageCode,
    showStoreInfo,
    // section1Headline,
    section1List,
    section2Headline,
    section2List,
    ctaText,
    // newsCarousel,
    section3Headline,
    section3List,
    section4Headline,
    section5Headline,
    section5Description,
    tagline,
    footerLinks,
    footerBlogAsset,
    privacyAnimation,
}) => {
    const [hasFiredVisit, setHasFiredVisit] = useState(false)

    useEffect(() => {
        if (!hasFiredVisit) {
            setHasFiredVisit(true)
            setExtensionParamsToLocalStorage(experiment)
            logDplWithExtensionParams(EVENTS.StartpagePageLoad, {
                experiment,
            })
        }
    }, [hasFiredVisit, experiment])

    const translations = {
        en: {
            pageTitle: 'Install Startpage Extension',
            pageDescription: "The World's Most Private Search Engine",
        },
        de: {
            pageTitle: 'Installiere die Erweiterung von Startpage',
            pageDescription: 'Die sicherste Suchmaschine der Welt',
        },
    }

    const text = translations[languageCode]

    const additionalNewsCarouselData = {
        en: [
            {
                logo: {
                    url: 'https://www.datocms-assets.com/4799/1610148151-bloomberg-business-logo.svg',
                },
                quote:
                    "Startpage is designed to retain your privacy. The engine doesn't collect data, doesn't keep tabs on your movements, and isn't owned by a gigantic corporation.",
                ariaLabel: 'Bloomberg Business',
            },
            {
                logo: {
                    url: 'https://www.datocms-assets.com/4799/1610148176-fox-news-logo.svg',
                },
                quote:
                    'If you’re looking for the quality of Google search results but still want to protect your privacy, check out Startpage.com.',
                ariaLabel: 'Fox News',
            },
            {
                logo: {
                    url: 'https://www.datocms-assets.com/4799/1610148211-techradar-logo.svg',
                },
                quote:
                    "Startpage's 'Anonymous View' link ... makes it incredibly easy for users to visit websites while protecting their privacy.",
                ariaLabel: 'Tech Radar',
            },
            {
                logo: {
                    url: 'https://www.datocms-assets.com/4799/1610148232-fast-company-logo.svg',
                },
                quote: 'This alternative search engine is basically Google without the privacy headaches.',
                ariaLabel: 'Fast Company',
            },
        ],
        de: [
            {
                logo: {
                    url: 'https://www.datocms-assets.com/4799/1610489839-chip-logo.svg',
                },
                quote:
                    'Google ärgern: Anonym suchen und trotzdem alles finden: Mit Startpage nutzen Sie die Suchmaschine Google, ohne dass der Internetkonzern Sie tracken kann.',
                ariaLabel: 'Chip',
            },
            {
                logo: {
                    url: 'https://www.datocms-assets.com/4799/1610488064-computerbild-logo.svg',
                },
                quote:
                    'Gehen Sie auf Nummer sicher, vertrauen Sie der sicherste Suchmaschine der Welt und lassen Startpage für Ihre Privatsphäre arbeiten. Viele Millionen User weltweit schätzen bereits die ausgezeichneten Dienste der preisgekrönten Suchmaschine.',
                ariaLabel: 'Computer Bild',
            },
            {
                logo: {
                    url: 'https://www.datocms-assets.com/4799/1610488244-handelsblatt-logo.svg',
                },
                quote:
                    'Die niederländische Suchmaschine sitzt auf Google und liefert auch weitgehend identische Ergebnisse, gibt aber die Adresse des Suchers nicht weiter.',
                ariaLabel: 'Handelsblatt',
            },
            {
                logo: {
                    url: 'https://www.datocms-assets.com/4799/1610487914-t3n.png',
                },
                quote:
                    'Startpage erfasst im Gegensatz zu Google selbst keine IP-Adressen der Nutzer und speichert auch keine Cookies zur Identifizierung ab.',
                ariaLabel: 'T 3 N Digital Pioneers',
            },
        ],
    }

    const newsCarouselData = additionalNewsCarouselData[languageCode]

    return (
        <>
            <Global
                styles={css`
                    body {
                        min-width: 1200px;
                    }
                `}
            />
            <SEO
                title={text.pageTitle}
                description={text.pageDescription}
                favicons={legacyExtensionFavicons}
                ogImagePath="https://www.startpage.com/sp/cdn/favicons/extension/sp_icon_128.png"
            />
            <FirefoxMask languageCode={languageCode} />
            <div
                className="sticky-header"
                css={css`
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: 9999;
                `}
            >
                <Header experiment={experiment} ctaText={ctaText} languageCode={languageCode} />
            </div>
            <div
                className="main-content"
                css={css`
                    padding-top: 91px;
                `}
            >
                <Section1
                    experiment={experiment}
                    languageCode={languageCode}
                    showStoreInfo={showStoreInfo}
                    // headline={section1Headline}
                    bulletList={section1List}
                    tagline={tagline}
                    ctaText={ctaText}
                />
                <Section2
                    experiment={experiment}
                    languageCode={languageCode}
                    headline={section2Headline}
                    bulletList={section2List}
                    tagline={tagline}
                    ctaText={ctaText}
                />
                <Section3
                    experiment={experiment}
                    headline={section3Headline}
                    bulletList={section3List}
                    tagline={tagline}
                    ctaText={ctaText}
                    languageCode={languageCode}
                />
                <Section4
                    experiment={experiment}
                    newsCarousel={newsCarouselData}
                    headline={section4Headline}
                    tagline={tagline}
                    ctaText={ctaText}
                    languageCode={languageCode}
                />
                <Section5
                    experiment={experiment}
                    showStoreInfo={showStoreInfo}
                    languageCode={languageCode}
                    headline={section5Headline}
                    description={section5Description}
                    tagline={tagline}
                    ctaText={ctaText}
                />
                <Footer links={footerLinks} languageCode={languageCode} footerBlogAsset={footerBlogAsset} />
            </div>
        </>
    )
}

Index4624.propTypes = {
    experiment: PropTypes.string,
    languageCode: PropTypes.string,
    showStoreInfo: PropTypes.bool,
    section1Headline: PropTypes.string,
    section1List: PropTypes.array,
    section2Headline: PropTypes.string,
    section2List: PropTypes.array,
    ctaText: PropTypes.string,
    installsChrome: PropTypes.string,
    installsFirefox: PropTypes.string,
    newsCarousel: PropTypes.array,
    ratingChrome: PropTypes.string,
    ratingFirefox: PropTypes.string,
    section3Headline: PropTypes.string,
    section3List: PropTypes.array,
    section4Headline: PropTypes.string,
    section5Headline: PropTypes.string,
    section5Description: PropTypes.string,
    tagline: PropTypes.string,
    footerLinks: PropTypes.array,
}

Index4624.defaultProps = {
    experiment: `sp-415`,
    languageCode: `en`,
    showStoreInfo: true,
    section1Headline: `The World's Most Private Search Engine`,
    section1List: [
        {
            bulletText: ``,
        },
    ],
    section2Headline: `How Your Search Stays Private`,
    section2List: [
        {
            bulletText: ``,
        },
    ],
    ctaText: `Add to {}`,
    installsChrome: `210,000+`,
    installsFirefox: `2.5M+`,
    newsCarousel: [
        {
            quote: ``,
            logo: {
                url: ``,
            },
        },
    ],
    ratingChrome: `4.1 rating`,
    ratingFirefox: `4.1 rating`,
    section3Headline: `Private Search Engine Benefits`,
    section3List: [
        {
            bulletText: ``,
        },
    ],
    section4Headline: `What Industry Experts Are Saying`,
    section5Headline: `Our Mission`,
    section5Description: `Startpage's mission is to protect people's worldwide right to privacy. Take back control of your data rights today!`,
    tagline: `Set Startpage as your default search engine to search from your browser.`,
    footerLinks: [
        {
            text: `Privacy policy`,
            url: `https://www.startpage.com/en/search/privacy-policy.html`,
        },
        {text: `About us`, url: `https://www.startpage.com/en/about-us.html`},
        {text: `Blog`, url: `https://www.startpage.com/privacy-please/`},
        {text: `Press`, url: `https://www.startpage.com/privacy-please/press/`},
        {text: `StartMail`, url: `https://www.startmail.com/en/startpage/?pk_campaign=startpage`},
    ],
}

export default Index4624
