import {getAllAppParams} from './appMetadata'

export const logHoudini = (experimentId = 'app-v1') => {
    const allAppParams = getAllAppParams(experimentId)
    const houdiniUrl = new URL('https://hct.tmt.startpage.com/push')
    Object.keys(allAppParams).forEach((key) => {
        houdiniUrl.searchParams.append(key, allAppParams[key])
    })
    return fetch(houdiniUrl)
}
