import {css} from '@emotion/react'
import {colors} from '../../../constants'
import IcnCheck from '../../../images/app/icn-check.svg'
import IcnX from '../../../images/app/icn-x.svg'

const translations = {
    en: {
        title: 'The Startpage difference',
        startpageSearch: 'Startpage search',
        nonPrivateSearchApps: 'Non-Private search',
        ipAddressProtection: 'IP Address Protection',
        preventsThirdPartyAds: 'Prevents third-party retargeting ads',
        unbiasedSearchResults: 'Unbiased search results',
        strictStandards: 'Strict European privacy standards',
    },
    de: {
        title: 'Startpage macht den Unterschied',
        startpageSearch: 'Startpage',
        nonPrivateSearchApps: 'Nicht-private Suchmaschinen',
        ipAddressProtection: 'Schutz deiner IP-Adresse',
        preventsThirdPartyAds: 'Verhindert personalisierte Anzeigen Dritter',
        unbiasedSearchResults: 'Unverfälschte Suchergebnisse',
        strictStandards: 'Strenge europäische Datenschutzbestimmungen',
    },
    nl: {
        title: 'Het Startpage verschil',
        startpageSearch: 'Startpage',
        nonPrivateSearchApps: 'Niet-privé zoekmachine',
        ipAddressProtection: 'IP-adres bescherming',
        preventsThirdPartyAds: 'Voorkomt gepersonaliseerde advertenties van derden',
        unbiasedSearchResults: 'Onpartijdige zoekresultaten',
        strictStandards: 'Strikte Europese regelgeving voor gegevensbescherming',
    },
}

const InfoGrid = ({languageCode}) => {
    const text = translations[languageCode]
    return (
        <div
            className="info-grid"
            css={css`
                border-radius: 10px;
                overflow: hidden;
                max-width: 400px;
                margin: 2rem auto;
                position: relative;
                .row {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    align-items: center;
                    padding: 20px 16px;
                    &:nth-of-type(even) {
                        background: ${colors.brandBlueLightest};
                    }
                    .cell-text {
                        text-align: left;
                        line-height: 22px;
                        color: ${colors.black};
                        max-width: 107px;
                        word-break: break-word;
                    }
                    .cell-text-de,
                    .cell-text-nl {
                        font-size: 14px;
                    }
                    .cell-header {
                        text-align: left;
                        color: ${colors.black};
                    }
                    .startpage {
                        margin: 0 auto;
                        max-width: 75px;
                    }
                    .non-private-search {
                        margin: 0 auto;
                        max-width: 96px;
                        word-break: break-word;
                    }
                }
                .table-header {
                    background: ${colors.whisperDarkest} !important;
                    padding: 20px 10px;
                }
            `}
        >
            <div
                className="highlight-column"
                css={css`
                    border: 3px solid ${colors.brandBlue};
                    border-radius: 10px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: calc(50% - 50px);
                    width: 102px;
                `}
            />
            <div
                className="table-header row"
                css={css`
                    font-weight: 800;
                `}
            >
                <div className="cell cell-header cell-header" />
                <div
                    className={`cell cell-header startpage cell-header__${languageCode}`}
                    css={css`
                        margin-top: 2px;
                    `}
                >
                    {text.startpageSearch}
                </div>
                <div
                    className={`cell cell-header non-private-search cell-header_${languageCode}`}
                    css={css`
                        /* max-width: 100px;
                        margin: 0 auto; */
                        /* margin-left: 15px; */
                    `}
                >
                    {text.nonPrivateSearchApps}
                </div>
            </div>
            <div className="row-1 row">
                <div className={`cell cell-text cell-text-${languageCode}`}>{text.ipAddressProtection}</div>
                <div className="cell">
                    <IcnCheck />
                </div>
                <div className="cell">
                    <IcnX />
                </div>
            </div>
            <div
                className="row-2 row"
                css={css`
                    background: ${colors.brandBlueLightest};
                `}
            >
                <div className={`cell cell-text cell-text-${languageCode}`}>{text.preventsThirdPartyAds}</div>
                <div className="cell">
                    <IcnCheck />
                </div>
                <div className="cell">
                    <IcnX />
                </div>
            </div>
            <div className="row-3 row">
                <div className={`cell cell-text cell-text-${languageCode}`}>{text.unbiasedSearchResults}</div>
                <div className="cell">
                    <IcnCheck />
                </div>
                <div className="cell">
                    <IcnX />
                </div>
            </div>
            <div
                className="row-5 row"
                css={css`
                    background: ${colors.brandBlueLightest};
                `}
            >
                <div className={`cell cell-text cell-text-${languageCode}`}>{text.strictStandards}</div>
                <div className="cell">
                    <IcnCheck />
                </div>
                <div className="cell">
                    <IcnX />
                </div>
            </div>
        </div>
    )
}

const Section3 = ({languageCode, experimentId, os, browser}) => {
    const text = translations[languageCode]
    return (
        <div
            className="section3"
            css={css`
                text-align: center;
                padding: 4rem 10px;
                h2 {
                    font-size: 32px;
                    color: ${colors.black};
                    max-width: 250px;
                    margin: auto;
                    font-weight: 800;
                }
                p {
                    margin: 32px 0;
                }
            `}
        >
            <h2>{text.title}</h2>
            <InfoGrid languageCode={languageCode} />
        </div>
    )
}

export default Section3
