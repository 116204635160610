/** @jsx jsx */
/* eslint-disable no-unused-vars */
import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'

const WidgetInstalls = ({text, image}) => {
    const firefoxLogo = useStaticQuery(graphql`
        query WidgetInstalls4624Query {
            browserLogo: file(relativePath: {eq: "logoFirefox.png"}) {
                childImageSharp {
                    fixed(height: 50, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)

    const [title, description] = text.split(' ')

    return (
        <>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    max-width: 11rem;
                `}
            >
                <Img
                    fixed={image || firefoxLogo.browserLogo.childImageSharp.fixed}
                    css={css`
                        margin: 0 0.5rem 0 0;
                    `}
                />
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    <span
                        css={css`
                            font-size: 20px;
                            font-weight: 700;
                        `}
                    >
                        {title}
                    </span>
                    <p
                        css={css`
                            line-height: 1;
                            font-size: 14px;
                        `}
                    >
                        {description}
                    </p>
                </div>
            </div>
        </>
    )
}

WidgetInstalls.propTypes = {
    text: PropTypes.string,
    image: PropTypes.object,
}

WidgetInstalls.defaultProps = {
    text: `210,000+ Installs`,
}

export default WidgetInstalls
