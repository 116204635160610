import {css} from '@emotion/react'
import Quote from '../../../images/quote.svg'
import {colors} from '../../../constants'

const NewsCard = ({text, imageUrl, ariaLabel = 'Bloomberg Business', languageCode}) => {
    return (
        <div
            className="news-card"
            css={css`
                background: ${colors.whisperLight};
                border-radius: 10px;
                flex: 0 0 312px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                padding: 16px;
                box-shadow: 0px 10px 20px rgba(32, 41, 69, 0.1);
            `}
        >
            <Quote aria-label="quotation mark" />
            <p
                className="review-text"
                css={css`
                    margin: 32px 0;
                `}
            >
                {text}
            </p>
            <img
                src={imageUrl}
                alt="news-logo"
                aria-label={ariaLabel}
                css={css`
                    max-width: 120px;
                    max-height: 56px;
                `}
            />
        </div>
    )
}

export default NewsCard
