/* eslint-disable no-param-reassign */
import {useEffect, useState} from 'react'
import {css} from '@emotion/react'
import {colors, gethomeLink} from '../../../constants'
import {isAndroid, isIOS, isUnsupportedOS} from '../../../util/userAgent'
import Button from './Button'

// import StartpageLogoRainbow from '../../../images/startpage-logo-rainbow.svg'
import StartpageLogoAppBeta from '../../../images/startpage-logo-app-beta.svg'
import AppIcon from '../../../images/app/app-icon.svg'
import IphoneImageEn from '../../../images/app/phones/iphone-en.svg'
import IphoneImageDe from '../../../images/app/phones/iphone-de.svg'
import IphoneImageNl from '../../../images/app/phones/iphone-nl.svg'
import HeroStars from '../../../images/app/hero-stars.svg'

const CONTAINER_MAX_WIDTH = 966

const translations = {
    en: {
        title: `Private search and beyond`,
        bodyText: `Search without tracking or profiling on Startpage.`,
    },
    de: {
        title: `Private Suche und mehr`,
        bodyText: `Suche ohne Tracking oder Profilerstellung mit Startpage.`,
    },
    nl: {
        title: `Privé zoeken en meer`,
        bodyText: `Zoek zonder tracking of profilering op Startpage.`,
    },
}

const Hero = ({experimentId, os, browser, languageCode}) => {
    const text = translations[languageCode]

    const getLocalizedImage = () => {
        switch (languageCode) {
            case 'de':
                return <IphoneImageDe />
            case 'nl':
                return <IphoneImageNl />
            default:
                return <IphoneImageEn />
        }
    }

    return (
        <section
            className="hero"
            css={css`
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, #000 0.45%, #17203b 29.1%, #6573ff 101.25%);
            `}
        >
            <div
                className="header"
                css={css`
                    width: 100%;
                    padding: 24px 24px 32px;
                `}
            >
                <div
                    className="header-inner"
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        max-width: ${CONTAINER_MAX_WIDTH}px;
                        margin: 0 auto;
                    `}
                >
                    <a
                        href={gethomeLink(languageCode)}
                        target="__blank"
                        rel="noopener noreferrer nofollow"
                        className="logo"
                        css={css`
                            display: block;
                            /* TODO: remove the styles below when you switch back to StartpageLogoRainbow */
                            transform: scale(0.75);
                            margin-left: -21px;
                            margin-top: -5px;
                            /* end */
                        `}
                    >
                        <StartpageLogoAppBeta />
                    </a>
                </div>
            </div>

            <div
                className="hero-body"
                css={css`
                    max-width: ${CONTAINER_MAX_WIDTH}px;
                    margin: 0 auto;
                `}
            >
                <div
                    className="stars-container"
                    css={css`
                        @keyframes blink {
                            0% {
                                opacity: 1;
                            }
                            50% {
                                opacity: 0;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                        position: absolute;
                        left: calc(50% - 150px);
                        top: 40px;
                        svg {
                            g {
                                &:nth-of-type(3) {
                                    animation: blink 0.5s linear;
                                    animation-delay: 0.5s;
                                }
                                &:nth-of-type(4) {
                                    animation: blink 0.5s linear;
                                    animation-delay: 3s;
                                }
                                &:nth-of-type(5) {
                                    animation: blink 0.5s linear;
                                    animation-delay: 4.5s;
                                }
                                &:nth-of-type(6) {
                                    animation: blink 0.5s linear;
                                    animation-delay: 2s;
                                }
                                &:nth-of-type(7) {
                                    animation: blink 0.5s linear;
                                    animation-delay: 4s;
                                }
                            }
                        }
                    `}
                >
                    <HeroStars />
                </div>
                <div
                    className="billboard"
                    css={css`
                        text-align: center;
                        h1,
                        p {
                            color: ${colors.whisperLight};
                        }
                        h1 {
                            font-size: 40px;
                            margin: 24px auto;
                            max-width: 300px;
                        }
                        p {
                            max-width: 200px;
                            margin: 0 auto;
                        }
                        .intro,
                        .title {
                            font-weight: 800;
                        }
                    `}
                >
                    <AppIcon />
                    <h1
                        className="title"
                        css={css`
                            opacity: ${text.title === `` ? '0' : '1'};
                            transition: opacity 0.2s ease;
                        `}
                    >
                        {text.title}
                    </h1>
                    <p className="subtitle">{text.bodyText}</p>
                    <div
                        className="cta-container"
                        css={css`
                            margin: 24px 0 50px;
                        `}
                    >
                        <Button
                            ctaType="cta-1"
                            experiment={experimentId}
                            os={os}
                            browser={browser}
                            languageCode={languageCode}
                        />
                    </div>
                </div>
                <div
                    className="computer"
                    css={css`
                        display: flex;
                        justify-content: center;
                    `}
                >
                    {getLocalizedImage()}
                </div>
            </div>
        </section>
    )
}

export default Hero
