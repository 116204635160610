/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'
import {convertDatoCmsImgPathsToLocal} from '../../../util/datoCmsDataFactory'
import Button from './button'

import {colors} from '../../../constants'
import NewsCard from './news-card'

const Section4 = ({experiment, newsCarousel, headline, tagline, ctaText, languageCode}) => {
    return (
        <section
            css={css`
                padding: 5rem 0;
                background: ${colors.purpleLightGradient};
                overflow: hidden;
            `}
        >
            <div
                className="container"
                css={css`
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                `}
            >
                <h2
                    css={css`
                        font-weight: 800;
                        text-align: center;
                        @media (max-width: 900px) {
                            transform: scale(0.85);
                        }
                    `}
                >
                    <span
                        className="highlight"
                        css={css`
                            background: linear-gradient(
                                180deg,
                                rgba(255, 255, 255, 0) 60%,
                                ${colors.whisperDarkest} 50%
                            );
                        `}
                    >
                        {headline}
                    </span>
                </h2>
                <div
                    className="carousel-container"
                    css={css`
                        margin: 3rem 0 3rem;
                        position: relative;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            gap: 16px;
                            max-width: 1150px;
                            margin-left: -150px;
                        `}
                    >
                        {newsCarousel.map((item, i) => {
                            const {quote, logo, ariaLabel} = item
                            return (
                                <NewsCard
                                    text={quote}
                                    imageUrl={convertDatoCmsImgPathsToLocal(logo.url)}
                                    ariaLabel={ariaLabel}
                                    languageCode={languageCode}
                                    key={i}
                                />
                            )
                        })}
                    </div>
                </div>

                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @media (max-width: 900px) {
                            transform: scale(0.85);
                        }
                    `}
                >
                    <p
                        css={css`
                            margin-bottom: 32px;
                            text-align: center;
                            font-size: 16px;
                        `}
                    >
                        {tagline}
                    </p>
                    <Button text={ctaText} ctaType="cta-5" experiment={experiment} languageCode={languageCode} />
                </div>
            </div>
        </section>
    )
}

Section4.propTypes = {
    experiment: PropTypes.string,
    newsCarousel: PropTypes.array,
    headline: PropTypes.string,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
}

Section4.defaultProps = {
    experiment: `sp-415`,
}

export default Section4
