/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import {graphql, useStaticQuery} from 'gatsby'
import Button from './button'
import {colors} from '../../../constants'

const Section2 = ({experiment, languageCode, headline, bulletList, tagline, ctaText}) => {
    const site = useStaticQuery(graphql`
        query Section24624Query {
            laptopGuy: file(relativePath: {eq: "4624/laptop-guy.png"}) {
                childImageSharp {
                    fixed(height: 366, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)

    const translations = {
        en: {
            imgAlt:
                'A man is searching the internet on a laptop computer, fully at ease knowing his searches are protected with Startpage, a private search engine.',
        },
        de: {
            imgAlt:
                'Ein Mann sucht im Internet auf einem Laptop-Computer und ist beruhigt, dass seine Suchvorgänge mit Startpage, einer privaten Suchmaschine, geschützt sind.',
        },
    }

    const text = translations[languageCode]

    return (
        <section
            css={css`
                padding: 50px 1rem 36px;
            `}
        >
            <div
                className="container"
                css={css`
                    max-width: 1150px;
                    margin: 0 auto;
                    justify-content: space-between;
                    align-items: center;
                    display: flex;
                `}
            >
                <div
                    className="left-side"
                    css={css`
                        display: flex;
                        flex: 0 0 50%;
                        flex-direction: column;
                        justify-content: center;
                    `}
                >
                    <Img alt={text.imgAlt} fixed={site.laptopGuy.childImageSharp.fixed} />
                </div>
                <div
                    className="right-side"
                    css={css`
                        margin: 0 5rem 0 0;
                    `}
                >
                    <div
                        css={css`
                            margin: 3rem 0;
                        `}
                    >
                        <h2
                            css={css`
                                font-size: 40px;
                                margin: 0 0 3rem 0;
                                max-width: 500px;
                                color: ${colors.midnight};
                                font-weight: 800;
                            `}
                        >
                            <span
                                className="highlight"
                                css={css`
                                    background: linear-gradient(
                                        180deg,
                                        rgba(255, 255, 255, 0) 60%,
                                        ${colors.tealLight} 50%
                                    );
                                `}
                            >
                                {headline}
                            </span>
                        </h2>
                        <div
                            className="checkmark-list"
                            css={css`
                                p {
                                    display: flex;
                                    align-items: center;
                                    margin: 0 0 16px;
                                    font-size: 16px;
                                    color: ${colors.midnight};

                                    span {
                                        border-radius: 50%;
                                        background: ${colors.purple};
                                        width: 32px;
                                        height: 32px;
                                        margin-right: 10px;
                                        color: ${colors.whisperLight};
                                        text-align: center;
                                        font-size: 20px;
                                        font-weight: 600;
                                        transform: scale(0.8);
                                    }
                                }
                                svg {
                                    margin-right: 1rem;
                                    height: 26px;
                                    width: 26px;
                                }
                            `}
                        >
                            {bulletList.map((item, i) => {
                                const {bulletText} = item
                                return (
                                    <p key={i}>
                                        <span>{i + 1}</span> {bulletText}
                                    </p>
                                )
                            })}
                        </div>
                        <p
                            css={css`
                                margin: 1.5rem 0;
                                max-width: 500px;
                                color: ${colors.midnight};
                                font-size: 16px;
                                b {
                                    color: ${colors.midnight};
                                }
                            `}
                        >
                            {tagline}
                        </p>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            `}
                        >
                            <Button
                                text={ctaText}
                                ctaType="cta-3"
                                experiment={experiment}
                                languageCode={languageCode}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

Section2.propTypes = {
    experiment: PropTypes.string,
    languageCode: PropTypes.string,
    headline: PropTypes.string,
    bulletList: PropTypes.array,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
}

Section2.defaultProps = {
    experiment: `sp-415`,
    languageCode: `en`,
}

export default Section2
