/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'

import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import Button from './button'

import CheckmarkIcon from '../../../images/checkmark-icon.svg'
import {colors} from '../../../constants'

const Section3 = ({experiment, headline, bulletList, tagline, ctaText, languageCode}) => {
    const site = useStaticQuery(graphql`
        query Section34624Query {
            cookieGirl: file(relativePath: {eq: "4624/cookie-girl.png"}) {
                childImageSharp {
                    fixed(height: 404, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)

    const translations = {
        en: {
            imgAlt:
                'A woman is holding her hand up to stop third party cookies, which is one of the benefits to using Startpage, a private search engine.',
        },
        de: {
            imgAlt:
                'Eine Frau hält ihre Hand hoch, um Third Party Cookies zu stoppen, was einer der Vorteile für die Verwendung von Startpage, der privaten Suchmaschine ist.',
        },
    }

    const text = translations[languageCode]

    return (
        <section
            css={css`
                padding: 36px 1rem 72px;
            `}
        >
            <div
                className="container"
                css={css`
                    max-width: 1150px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <div className="left-side">
                    <div>
                        <h2
                            css={css`
                                font-weight: 800;
                                font-size: 40px;
                                margin: 0 0 3rem 0;
                                max-width: 450px;
                            `}
                        >
                            <span
                                className="highlight"
                                css={css`
                                    background: linear-gradient(
                                        180deg,
                                        rgba(255, 255, 255, 0) 60%,
                                        ${colors.goldLight} 50%
                                    );
                                `}
                            >
                                {headline}
                            </span>
                        </h2>
                        <div
                            className="checkmark-list"
                            css={css`
                                p {
                                    display: flex;
                                    align-items: center;
                                    margin: 0 0 16px;
                                    font-size: 16px;
                                }
                                svg {
                                    margin-right: 10px;
                                    transform: scale(0.8);
                                }
                            `}
                        >
                            {bulletList.map((item, i) => {
                                const {bulletText} = item
                                return (
                                    <p key={i}>
                                        <CheckmarkIcon /> {bulletText}
                                    </p>
                                )
                            })}
                        </div>
                        <p
                            css={css`
                                margin: 1.5rem 0;
                                max-width: 500px;
                                font-size: 16px;
                            `}
                        >
                            {tagline}
                        </p>
                        <Button text={ctaText} ctaType="cta-4" experiment={experiment} languageCode={languageCode} />
                    </div>
                </div>

                <div
                    className="right-side"
                    css={css`
                        @media (min-width: 1101px) {
                            width: 45%;
                        }
                    `}
                >
                    <Img alt={text.imgAlt} fixed={site.cookieGirl.childImageSharp.fixed} />
                </div>
            </div>
        </section>
    )
}

Section3.propTypes = {
    experiment: PropTypes.string,
    headline: PropTypes.string,
    bulletList: PropTypes.array,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
}

Section3.defaultProps = {
    experiment: `sp-415`,
}

export default Section3
