/** @jsx jsx */
import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'

import {isAndroid, isIOS} from '../../../util/userAgent'
import {EVENTS, logDplWithAppParams} from '../../../util/dpl'
import {getCountry} from '../../../util/getCountry'
import {colors} from '../../../constants'
import {macDownloadLink, windowsDownloadLink} from '../../../util/browserDownloadLinks'

const translations = {
    en: {
        iphoneText: `Download for iPhone`,
        androidText: `Download for Android`,
        joinTheWaitlist: `Join the waitlist`,
    },
    de: {
        iphoneText: `Herunterladen für iPhone`,
        androidText: `Herunterladen für Android`,
        joinTheWaitlist: `Auf die Warteliste setzen`,
    },
    nl: {
        iphoneText: `Downloaden voor iPhone`,
        androidText: `Downloaden voor Android`,
        joinTheWaitlist: `Meld je aan voor de wachtlijst`,
    },
}

const buttonStylesDefault = css`
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    display: inline-block;
    font-family: inherit;
    letter-spacing: 0.02rem;
    line-height: 1.2;
    transition: background-color 0.3s cubic-bezier(0, 0.4, 0.74, 0.63);
    background-color: ${colors.teal};
    border: none;
    color: ${colors.black};
    padding: 12px 20px;
    font-weight: 800;
    box-shadow: 0px 20px 26px 0px rgba(0, 0, 0, 0.15);
    span {
        color: ${colors.black};
    }
    .button-inner {
        display: flex;
        align-items: center;
        .de-text {
            font-size: 13px;
        }
        .nl-text {
            font-size: 14px;
        }
        .en-text {
            font-size: 16px;
        }
    }
    .logo {
        margin-right: 10px;
    }
    &:hover {
        cursor: pointer;
        background-color: ${colors.tealLight2};
    }
`

const buttonStylesHeader = css`
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    display: inline-block;
    font-family: inherit;
    letter-spacing: 0.02rem;
    line-height: 1.2;
    transition: background-color 0.3s cubic-bezier(0, 0.4, 0.74, 0.63);
    background-color: ${colors.brandBlueLight3};
    border: none;
    color: ${colors.black};
    padding: 7px 20px;
    font-weight: 800;
    span {
        color: ${colors.black};
    }
    .button-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 21px;
        .de-text {
            font-size: 11px;
        }
        .nl-text {
            font-size: 12px;
        }
        .en-text {
            font-size: 14px;
        }
    }
    .logo {
        margin-right: 10px;
        filter: brightness(0) invert(1);
        svg {
            height: 17px;
            width: 14px;
        }
    }
    .text {
        color: ${colors.whisperLight};
        text-align: center;
    }
    &:hover {
        cursor: pointer;
        background-color: ${colors.brandBlueLight4};
    }
`

const Button = ({
    text,
    experiment,
    onClick,
    ctaType,
    ariaLabel,
    tabIndex = '0',
    languageCode = 'en',
    styleVariant = `default`,
    overrideStyles,
    os,
    browser,
    link = 'downloadLink',
}) => {
    // const [browserLogo, setBrowserLogo] = useState(null)
    const [ctaLink, setCtaLink] = useState('')
    const [ctaText, setCtaText] = useState('')

    const txt = translations[languageCode]

    useEffect(() => {
        setCtaText(txt.joinTheWaitlist)
        // if (isIOS()) {
        //     setCtaText(txt.iphoneText)
        // } else if (isAndroid()) {
        //     // TODO: use this when android app is released
        //     // setCtaText(txt.androidText)
        //     setCtaText(txt.joinTheWaitlist)
        // } else {
        //     setCtaText(txt.joinTheWaitlist)
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onBtnClickDownload = () => {
        logDplWithAppParams(EVENTS.StartpageAppCtaClick, {
            os,
            country: getCountry(),
            type: ctaType,
            experiment,
        }).then(() => {
            const event = new CustomEvent('showSuccessModal', {detail: {ctaType}})
            window.dispatchEvent(event)
        })
    }

    const onBtnClickWaitlist = (event) => {
        event.preventDefault()
        logDplWithAppParams(EVENTS.StartpageAppCtaClick, {
            os,
            country: getCountry(),
            type: ctaType,
            experiment,
        })
        const newsletterSection = document.querySelector('#waitlist-section')
        window.scrollTo({
            // @ts-ignore
            top: newsletterSection.offsetTop,
            behavior: 'smooth',
        })
    }

    return (
        <a
            href={ctaLink}
            download
            className="conversion-button"
            onClick={onBtnClickWaitlist}
            // leaving the code below intentionally. we will wanna switch to this eventually until android is done
            // onClick={onClick || isIOS() ? onBtnClickDownload : onBtnClickWaitlist}
            aria-label={ariaLabel || text || ctaText}
            css={css`
                ${styleVariant === 'header' ? buttonStylesHeader : buttonStylesDefault}
                ${overrideStyles}
            `}
            tabIndex={tabIndex}
            type="button"
        >
            <div
                className="button-inner"
                css={css`
                    transition: opacity 0.2s ease;
                `}
            >
                <span className={`text ${languageCode}-text`}>{text || ctaText}</span>
            </div>
        </a>
    )
}

Button.propTypes = {
    experiment: PropTypes.string,
    onClick: PropTypes.func,
    ctaType: PropTypes.string.isRequired,
    text: PropTypes.string,
    ariaLabel: PropTypes.string,
    overrideStyles: PropTypes.string,
}

Button.defaultProps = {
    experiment: `control`,
    // ctaType: `cta-1`,
    // text: `Download for iPhone`,
    // ariaLabel: `Download for iPhone`,
    overrideStyles: ``,
}

export default Button
