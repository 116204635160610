import {css} from '@emotion/react'
import Button from './Button'
import Section2Stars from '../../../images/app/section2-stars.svg'

const translations = {
    en: {
        title: 'Explore securely',
        description: "By searching with Startpage, you're protected from the start.",
    },
    de: {
        title: 'Sicher surfen',
        description: 'Bei der Suche mit Startpage bist du von Anfang an geschützt.',
    },
    nl: {
        title: 'Veilig verkennen',
        description: 'Als je met Startpage zoekt, ben je vanaf het begin beschermd.',
    },
}

const Section2 = ({languageCode, experimentId, os, browser}) => {
    const text = translations[languageCode]
    return (
        <div
            className="section2"
            css={css`
                position: relative;
                text-align: center;
                background: linear-gradient(0deg, #17203b 0%, #6677fb 100%);
                padding: 4rem 2rem;
                h2,
                p {
                    color: #fff;
                }
                h2 {
                    font-size: 32px;
                    font-weight: 800;
                }
                p {
                    margin: 24px auto;
                    max-width: 260px;
                }
            `}
        >
            <h2>{text.title}</h2>
            <p>{text.description}</p>
            <Button ctaType="cta-2" experiment={experimentId} os={os} browser={browser} languageCode={languageCode} />
            <div
                className="stars-container"
                css={css`
                    position: absolute;
                    bottom: 20px;
                    right: calc(50% - 130px);
                `}
            >
                <Section2Stars />
            </div>
        </div>
    )
}

export default Section2
