/** @jsx jsx */
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'
import Star from '../../../images/star.svg'

const WidgetRatings = ({description}) => {
    return (
        <div className="widget-ratings">
            <div
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <p
                    css={css`
                        margin: 0 5px 0 0;
                        font-size: 20px;
                        font-weight: 700;
                    `}
                >
                    {description.split(' ')[0]}
                </p>
                <Star />
            </div>
            <p
                css={css`
                    margin: -9px 0 0 0;
                    font-size: 14px;
                `}
            >
                {description.split(' ')[1]}
            </p>
        </div>
    )
}

WidgetRatings.propTypes = {
    description: PropTypes.string,
}

WidgetRatings.defaultProps = {
    description: `4.6 rating`,
}

export default WidgetRatings
